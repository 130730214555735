import React from "react";
import Navigation from "../components/navigationModule";
import Footer from "../components/footer";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Styles from "../components/projectitem.module.css";
import Img from 'gatsby-image';
import {concatenateContentfullParagraphs, isEmptyArray, isNullUndefined} from "../util/util";
import Slider from "react-slick";
import get from 'lodash/get';
import ScrollAnimation from 'react-animate-on-scroll'
import {Link} from "gatsby"

const window = require("global/window");

class ProjectItem extends React.Component {

    // componentDidMount(){
    //     console.log(this.props)
    // }

    render() {
        
        let currentProjectItem = get(this.props, 'data.contentfulProjectItem')

        let settings = {
            dots: true,
            infinite: true,
            arrows: true,
            speed: 500,
            slidesToShow: 1,
            autoplay: true,
            slidesToScroll: 1
        };

        // console.log('currentProjectItem', currentProjectItem);

        if (isNullUndefined(currentProjectItem)) {
            return null;
        }
        // console.log('currentProjectItem after null check', currentProjectItem);


        let projectsTopDescription = !isNullUndefined(currentProjectItem) && currentProjectItem.projectsTopDescription &&
            currentProjectItem.projectsTopDescription.content &&
            currentProjectItem.projectsTopDescription.content;
        // console.log('projectsTopDescription', projectsTopDescription);

        let projectDescription = !isNullUndefined(currentProjectItem) && currentProjectItem.projectDescription &&
            currentProjectItem.projectDescription.content &&
            currentProjectItem.projectDescription.content;
        // console.log('projectDescription', projectDescription);


        let servicesProvidedDescription = !isNullUndefined(currentProjectItem) && currentProjectItem.servicesProvidedDescription &&
            currentProjectItem.servicesProvidedDescription.content &&
            currentProjectItem.servicesProvidedDescription.content;
        // console.log('servicesProvidedDescription', servicesProvidedDescription);

        let shortDescription = !isNullUndefined(currentProjectItem) && currentProjectItem.shortDescription &&
            currentProjectItem.shortDescription &&
            currentProjectItem.shortDescription;
        // console.log('shortDescription', shortDescription);

        let seniorStaffInvolvedAndFunctionsPerformed = !isNullUndefined(currentProjectItem) && currentProjectItem.seniorStaffInvolvedAndFunctionsPerformed &&
            currentProjectItem.seniorStaffInvolvedAndFunctionsPerformed.content &&
            currentProjectItem.seniorStaffInvolvedAndFunctionsPerformed.content;
        // console.log('seniorStaffInvolvedAndFunctionsPerformed', seniorStaffInvolvedAndFunctionsPerformed);

        let seniorStaffInvolvedAnd = seniorStaffInvolvedAndFunctionsPerformed &&
            seniorStaffInvolvedAndFunctionsPerformed[0] &&
            seniorStaffInvolvedAndFunctionsPerformed[0].content &&
            seniorStaffInvolvedAndFunctionsPerformed[0].content;
        // console.log('seniorStaffInvolvedAnd', seniorStaffInvolvedAnd);

        let functionsPerformed = seniorStaffInvolvedAndFunctionsPerformed &&
            seniorStaffInvolvedAndFunctionsPerformed[1] &&
            seniorStaffInvolvedAndFunctionsPerformed[1].content &&
            seniorStaffInvolvedAndFunctionsPerformed[1].content;
        // console.log('functionsPerformed', functionsPerformed);

        let sliderImages = !isNullUndefined(currentProjectItem) &&
            !isEmptyArray(currentProjectItem.projectImages) &&
            currentProjectItem.projectImages.map((item, i) => {
                return (
                    <SliderImage key={i} image={item} index={i + 1}/>
                );
            });
        // console.log('sliderImages', sliderImages);

        return (
            <>
                <Navigation/>
                <SEO title="project"/>
                <div className={Styles.project_item_banner}>
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <div className={Styles.text}>
                                    <h2>{currentProjectItem && currentProjectItem.name && currentProjectItem.name}</h2>
                                    <p>
                                        {shortDescription}
                                    </p>
                                    <ol className="breadcrumb">

                                        <li className="breadcrumb-item" onClick={() => window.history.back()}><Link to="/projects">Projects</Link></li>
                                        <li className="breadcrumb-item active"
                                            aria-current="page">{currentProjectItem && currentProjectItem.name && currentProjectItem.name}</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"container"}>
                            <button className="go_back" onClick={() => window.history.back()}>
                            <span className="circle" aria-hidden="true">
                              <span className="icon arrow"></span>
                            </span>
                                <span className="button-text">Go back</span>
                            </button>

                        </div>
                    </div>
                </div>

                <div className={Styles.single_project}>
                    <div className="container project_item_page">
                        <div className="row">
                            <div className="col-md-12 col-12 col-sm-12">
                                <div className={Styles.single_project_image}>
                                    <ScrollAnimation animateIn="fadeIn">
                                        <Slider {...settings}>
                                            {sliderImages}
                                        </Slider>
                                    </ScrollAnimation>

                                </div>
                            </div>

                            

                        </div>

                        <div className={"row"}>
                            <div className="col-md-12 col-12 col-sm-12">
                                <div className={Styles.category_information}>
                                    <ScrollAnimation animateIn="fadeIn">
                                        <h1>What we did</h1>
                                        {concatenateContentfullParagraphs(projectDescription)}
                                        <div className={"project_top_description"}>
                                            {concatenateContentfullParagraphs(projectsTopDescription)}
                                        </div>

                                    </ScrollAnimation>

                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-12 col-12 col-sm-12">
                                <div className={Styles.more_details_about_project}>
                                    <div className={Styles.Name_of_Senior_Staff}>
                                        <ScrollAnimation animateIn="fadeIn">
                                            <h1>Services provided</h1>


                                            {concatenateContentfullParagraphs(servicesProvidedDescription)}

                                        </ScrollAnimation>

                                    </div>


                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <Footer/>
            </>
        )
    }
}

export function AssociatedFirmDetail(props) {
    let [heading, details] = props.item;
    return (
        <p><span><strong>{heading.value}:</strong></span>&nbsp;{details.value}</p>
    );
}

export function SliderImage(props) {
    let {image, index} = props;
    return (
        <div className={Styles.slider_image}>

            <Img alt={`image-${index}`} sizes={image.sizes}/>

        </div>
    );
}

export default ProjectItem;

export const query = graphql`
    query projectBySlug($slug: String!) {
        contentfulProjectItem(slug: { eq: $slug }) {

            shortDescription

            image {
                file {
                    url
                }
            }
            name


            projectDescription {
                content {
                    content {
                        value
                    }
                }
            }
            projectsTopDescription {
                content {
                    content {
                        value
                        marks {
                            type
                        }
                    }
                }
            }

            seniorStaffInvolvedAndFunctionsPerformed {
                content {
                    content {
                        value
                    }
                }
            }
            servicesProvidedDescription {
                content {
                    content {
                        value
                    }
                }
            }
            projectImages {
                sizes(resizingBehavior: SCALE) {
                    ...GatsbyContentfulSizes_withWebp
                }
            }
            category
            projectImages {
                file {
                    url
                }
            }
            slug
        }
    }
`;
